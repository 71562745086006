/* eslint-disable @typescript-eslint/dot-notation */
import React, { ChangeEvent, ComponentProps, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { CSSObjectWithLabel } from 'react-select';
import DropdownInput from '../../components/inputs/DropdownInput';
import LabeledTextInput from '../../components/inputs/LabeledTextInput';
import BirthDateInput from '../../components/inputs/BirthDateInput';
import MessageBubble from '../../components/misc/MessageBubble';
import { UserDetailsInputType, UserDetailsType } from '../../types/userDetails';
import ContentPanel from '../../components/layouts/ContentPanel';
import { AUTO_COMPLETES, SB_LIGHT_BLUE, SB_LIGHT_PINK, SB_PINK, SB_PURPLE } from '../../constants';
import Labeled from '../../components/misc/Labeled';
import { updateField } from '../../redux/slices/userDetailsSlice';
import useUserDetails from '../../hooks/selectors/useUserDetails';
import { userDetailsValidators, validateUserDetails } from '../../utils/validationUtils';
import Event from '../../utils/event';
import { EventType } from '../../types/misc';
import useLanguage from '../../hooks/selectors/useLanguage';
import useEffectAfterFirstRender from '../../hooks/useEffectAfterFirstRender';

export default function UserDetailsPage(): JSX.Element {
  const dispatch = useDispatch();
  const { userDetails } = useUserDetails() ?? {};
  const { salutation, birthdate, email, confirmEmail } = userDetails ?? {};
  const lang = useLanguage();
  const { t } = useTranslation();

  const [errors, setErrors] = useState<Partial<Record<keyof UserDetailsInputType, string>>>({});

  const setAllErrors = (): void => setErrors(validateUserDetails(userDetails ?? {}));

  useEffect((): void => {
    if (!confirmEmail) return;
    setErrors({
      ...errors,
      confirmEmail: userDetailsValidators['confirmEmail'](confirmEmail ?? '', email ?? ''),
    });
  }, [email]);

  // for when user submits form in the shopping layout
  useEffect((): (() => void) => {
    Event.addListener(EventType.SUBMIT_USER_DETAILS, setAllErrors);

    return (): void => {
      Event.removeListener(EventType.SUBMIT_USER_DETAILS, setAllErrors);
    };
  }, [userDetails]);

  // For when page is reloaded, if any field is filled in validate them all since user already visited page
  useEffect((): void => {
    if (Object.values(userDetails ?? {}).some((v): boolean => !!v)) setAllErrors();
  }, []);

  // error translations are not triggered by language change
  useEffectAfterFirstRender((): void => {
    const translated = Object.fromEntries(
      Object.entries(validateUserDetails(userDetails ?? {})).filter(
        ([k]): boolean => errors[k as keyof typeof errors] !== undefined,
      ),
    );
    setErrors(translated);
  }, [lang]);

  useEffect((): void => {
    if (birthdate === null) {
      dispatch(updateField({ field: 'birthdate', value: undefined }));
      setErrors((prev): Partial<Record<keyof UserDetailsInputType, string>> => ({ ...prev, birthdate: userDetailsValidators.birthdate(undefined) }));
    }
  }, []);
  
  const handleInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    dispatch(
      updateField({ field: name as unknown as keyof Omit<UserDetailsType, 'birthdate'>, value }),
    );
    if (errors[name as keyof typeof errors] === undefined) return;
    let err = '';
    if (name === 'confirmEmail') err = userDetailsValidators[name](value, email ?? '');
    else err = userDetailsValidators[name as keyof Omit<UserDetailsType, 'birthdate'>](value);
    setErrors((prev): Partial<Record<keyof UserDetailsInputType, string>> => ({ ...prev, [name]: err }));
  };

  const salutations = [
    { value: 'mr', label: t("form.salutations.mr") },
    { value: 'mrs', label: t("form.salutations.mrs") },
    { value: 'other', label: t("form.salutations.other") },
  ];

  const getInputProps = (
    name: keyof Omit<UserDetailsInputType, 'salutation' | 'birthdate'>,
  ): ComponentProps<typeof LabeledTextInput> => ({
    name,
    label: t(`form.label.${name}`),
    value: userDetails?.[name],
    autoComplete: AUTO_COMPLETES[name],
    onChange: handleInputChange,
    errorMessage: errors[name],
    valid: errors[name] === undefined ? undefined : !errors[name],
    onBlur: (): void => {
      if (!userDetails?.[name] && errors[name] === undefined) return;
      setErrors((prev): Partial<Record<keyof UserDetailsInputType, string>> => ({
        ...prev,
        [name]:
          name === 'confirmEmail'
            ? userDetailsValidators['confirmEmail'](confirmEmail ?? '', email ?? '')
            : userDetailsValidators[name](userDetails?.[name] ?? ''),
      }));
    },
    mandatory: true,
  });

  return (
    <ContentPanel>
      <form className="gap-4 flex flex-col w-full my-2" onSubmit={(): void => {}}>
        <Labeled label={`${t('form.label.salutation')}:*`}>
          <DropdownInput
            value={salutation}
            options={salutations}
            placeholder={t('form.label.salutation')}
            setValue={(v): void => {
              dispatch(updateField({ field: 'salutation', value: v as string }));
              setErrors((prev): Partial<Record<keyof UserDetailsInputType, string>> => ({
                ...prev,
                salutation: userDetailsValidators.salutation(v as string),
              }));
            }}
            styles={{
              control: (): CSSObjectWithLabel => ({
                borderWidth: errors['salutation'] ? '1px' : '0px',
                borderColor: SB_PINK,
                padding: '0px 15px',
                background:
                  errors['salutation'] === undefined
                    ? SB_LIGHT_BLUE
                    : errors['salutation']
                    ? SB_LIGHT_PINK
                    : SB_PURPLE,
              }),
            }}
          />
          {errors['salutation'] && (
            <label className="text-sb-pink text-[15px] leading-[21px] ml-2.5">
              {errors['salutation']}
            </label>
          )}
        </Labeled>
        <LabeledTextInput {...getInputProps('firstName')} />
        <LabeledTextInput {...getInputProps('lastName')} />
        <BirthDateInput
          date={birthdate}
          setDate={(date): void => {
            dispatch(updateField({ field: 'birthdate', value: date }));
            if (!errors.birthdate) return;
            setErrors((prev): Partial<Record<keyof UserDetailsInputType, string>> => ({ ...prev, birthdate: userDetailsValidators.birthdate(date) }));
          }}
          errorMessage={errors['birthdate']}
          valid={errors['birthdate'] === undefined ? undefined : !errors['birthdate']}
          onBlur={(): void => {
            if (!userDetails?.birthdate && errors.birthdate === undefined) return;
            setErrors((prev): Partial<Record<keyof UserDetailsInputType, string>> => ({
              ...prev,
              birthdate: userDetailsValidators.birthdate(birthdate),
            }));
          }}
        />
        <LabeledTextInput {...getInputProps('email')} />
        <LabeledTextInput {...getInputProps('confirmEmail')} />
        <MessageBubble>{t('page.userDetails.emailBubble')}</MessageBubble>
        <LabeledTextInput {...getInputProps('zipCode')} />
        <LabeledTextInput {...getInputProps('city')} />
      </form>
    </ContentPanel>
  );
}