import React, { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import useShoppingCart from '../../hooks/selectors/useShoppingCart';
import useShop from '../../hooks/selectors/useShop';
import { SubscriptionPurchaseDto } from '../../types/subscription';
import { CardholderInputType } from '../../types/cardholders';
import ContentPanel from '../../components/layouts/ContentPanel';
import useCardholders from '../../hooks/selectors/useCardholders';
import { AUTO_COMPLETES } from '../../constants';
import { cardholderValidators, validateCardholderDetails } from '../../utils/validationUtils';
import LabeledTextInput from '../../components/inputs/LabeledTextInput';
import BirthDateInput from '../../components/inputs/BirthDateInput';
import { store } from '../../redux/store';
import { editCardholder } from '../../redux/slices/cardholdersSlice';
import PictureInput from '../../components/inputs/PictureInput';
import { cardholdersService } from '../../services';
import useCrashHandler from '../../hooks/useCrashHandler';
import Event from '../../utils/event';
import { EventType } from '../../types/misc';
import useLanguage from '../../hooks/selectors/useLanguage';
import useEffectAfterFirstRender from '../../hooks/useEffectAfterFirstRender';

export default function SubscriptionCardholdersPage(): JSX.Element {
  const { purchases } = useShoppingCart();
  return (
    <div className='flex flex-col gap-4'>
      {purchases.map((p): JSX.Element => (
        <PurchaseCardholdersComponent key={p.purchaseId} purchase={p} />
      ))}
    </div>
  );
}

interface PurchaseCardholdersProps {
  purchase: SubscriptionPurchaseDto;
}

function PurchaseCardholdersComponent({
  purchase: { subscriptionTypeId, purchaseId },
}: PurchaseCardholdersProps): JSX.Element | null {
  const { subscriptionTypes } = useShop();
  const { purchases } = useShoppingCart();
  const { cardholders } = useCardholders();

  const subscriptionType = subscriptionTypes.find((st): boolean => st.id === subscriptionTypeId);
  const sameItemPurchases = purchases.filter((p): boolean => p.subscriptionTypeId === subscriptionTypeId);
  const index = sameItemPurchases.findIndex((p): boolean => p.purchaseId === purchaseId) + 1;

  if (!subscriptionType) return null;

  const { name } = subscriptionType;
  return (
    <ContentPanel>
      <h1 className="text-[24px] leading-[24px] font-ginto-bold">
        {name}
        {sameItemPurchases.length > 1 && ` #${index}`}
      </h1>
      <div className="flex flex-col gap-6">
        {cardholders[purchaseId]?.map((cardholder): JSX.Element => (
          <CardHoldersInputComponent
            key={cardholder.id}
            purchaseId={purchaseId}
            cardholder={cardholder}
          />
        ))}
      </div>
    </ContentPanel>
  );
}

interface CardHoldersInputComponentProps {
  purchaseId: string;
  cardholder: CardholderInputType;
}

function CardHoldersInputComponent({
  purchaseId,
  cardholder,
}: CardHoldersInputComponentProps): JSX.Element {
  const { pictureId } = cardholder;
  const { pictures, cardholders } = useCardholders();
  const crashHandler = useCrashHandler();
  const { t } = useTranslation();
  const lang = useLanguage();
  const [errors, setErrors] = useState<Partial<Record<keyof CardholderInputType, string>>>({});

  const setAllErrors = (): void => setErrors(validateCardholderDetails(cardholder));

  // for when submit is pressed in the shoplayout
  useEffect((): (() => void) => {
    Event.addListener(EventType.SUBMIT_CARDHOLDERS, setAllErrors);

    return (): void => {
      Event.removeListener(EventType.SUBMIT_CARDHOLDERS, setAllErrors);
    };
  }, [cardholder]);

  // for tranlating
  useEffectAfterFirstRender((): void => {
    const translated = Object.fromEntries(
      Object.entries(validateCardholderDetails(cardholder)).filter(
        ([k]): boolean => errors[k as keyof typeof errors] !== undefined,
      ),
    );
    setErrors(translated);
  }, [lang]);

  // For when page is reloaded, if any field is filled in validate them all since user already visited page
  useEffect((): void => {
    if (cardholders[purchaseId]?.some((c): boolean => Object.values(_.omit(c, 'id') ?? {}).some((v): boolean => !!v)))
      setAllErrors();
  }, []);

  useEffect((): void => {
    if (cardholder.birthdate === null) {
      handleFieldChange('birthdate', undefined);
      setErrors((prev): Partial<Record<keyof CardholderInputType, string>> => ({
        ...prev,
        birthdate: cardholderValidators.birthdate(undefined),
      }));
    }
  }, []);

  const handlePictureChange = async (url: string | undefined): Promise<void> => {
    if (url) {
      const file = await fetch(url).then((res): Promise<File> => res.blob() as Promise<File>);
      cardholdersService.uploadPicture(purchaseId, cardholder.id, file).catch(crashHandler);
    } else cardholdersService.deletePicture(purchaseId, cardholder.id).catch(crashHandler);
  };

  const handleFieldChange = (
    key: keyof Omit<CardholderInputType, 'id' | 'pictureId'>,
    value: Date | null | string | undefined,
  ): void => {
    const newCardholder = { ...cardholder, [key]: value };
    store.dispatch(editCardholder({ purchaseId, cardholder: newCardholder }));
    if (errors[key] === undefined) return;
    const err = cardholderValidators[key](value as string & Date);
    setErrors((prev): Partial<Record<keyof CardholderInputType, string>> => ({ ...prev, [key]: err }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    if (name === 'firstName' || name === 'lastName') {
      handleFieldChange(name, value);
    }
  };

  const getInputProps = (
    name: keyof Omit<CardholderInputType, 'id' | 'pictureId' | 'birthdate'>,
  ): ComponentProps<typeof LabeledTextInput> => ({
    name,
    label: t(`form.label.${name}`),
    value: cardholder?.[name],
    autoComplete: AUTO_COMPLETES[name],
    onChange: handleInputChange,
    errorMessage: errors[name],
    valid: errors[name] === undefined ? undefined : !errors[name],
    onBlur: (): void => {
      if (!cardholder?.[name] && errors[name] === undefined) return;
      setErrors((prev): Partial<Record<keyof CardholderInputType, string>> => ({
        ...prev,
        [name]: cardholderValidators[name](cardholder?.[name] ?? ''),
      }));
    },
    mandatory: true,
  });

  return (
    <div className="flex flex-col sm:flex-row gap-4">
      <div className="flex flex-col gap-4 sm:w-[140px] sm:text-center">
        <PictureInput
          image={pictureId ? pictures[pictureId] : undefined}
          setImage={handlePictureChange}
        />
        {!pictureId && (
          <p className="text-[15px] leading-[21px] whitespace-break-spaces">
            {t('page.cardholders.pictureCaption')}
          </p>
        )}
      </div>
      <div className="flex flex-col gap-4 w-full">
        <LabeledTextInput {...getInputProps('firstName')} />
        <LabeledTextInput {...getInputProps('lastName')} />
        <BirthDateInput
          date={cardholder.birthdate}
          setDate={(v): void => handleFieldChange('birthdate', v)}
          errorMessage={errors.birthdate}
          valid={errors.birthdate === undefined ? undefined : !errors.birthdate}
          onBlur={(): void => {
            if (!cardholder?.birthdate && errors.birthdate === undefined) return;
            setErrors((prev): Partial<Record<keyof CardholderInputType, string>> => ({
              ...prev,
              birthdate: cardholderValidators.birthdate(cardholder?.birthdate),
            }));
          }}
        />
      </div>
    </div>
  );
}
