// eslint-disable-next-line import/no-cycle
import { cartService } from '.';
import { createBackup } from '../redux/slices/shoppingCartSlice';
import { store } from '../redux/store';
import {
  CreateSubscriptionPaymentDto,
  GooglePaymentData,
  PaymentCreationSuccessResponse,
  PaymentMethod,
  PaymentMethodId,
  PaymentTokenBody,
} from '../types/payment';
import { getCardholders } from './cardholdersService';
import httpService from './httpService';

import { getUserDetailsType } from './userDetailsService';
import { SubscriptionPurchaseDto } from '../types/subscription';

/**
 * Returns the available payment methods
 *
 * @returns The payment methods
 */
export function getPaymentMethods(): Promise<PaymentMethod[]> {
  return httpService
    .get<PaymentMethod[]>('/paymentmethods')
    .then(({ data }): PaymentMethod[] => data);
}

/**
 * Creates a payment
 *
 * @param paymentMethod The payment method and issuer
 * @returns The redirect URL
 */
export async function createPayment({ method, issuer }: PaymentMethodId, agreement: string[]): Promise<string> {
  store.dispatch(createBackup());
  await cartService.updateCart().catch((err): Promise<never> => Promise.reject(err));
  const userDetails = {
    ...getUserDetailsType(),
    languagePreference: store.getState().application.language,
    agreement,
  };
  const {
    purchases: purchaseItems,
    price: totalPrice,
  } = store.getState().shoppingCart;
  const usedCodes = store.getState().shoppingCart.discounts.filter((code): boolean => code.times > 0);
  const { discountedPrice, price } = totalPrice.bookingFee;
  const purchases = purchaseItems.map(({ purchaseId, ...p }):  Omit<SubscriptionPurchaseDto, 'purchaseId'> => ({
    ...p,
    cardholders: getCardholders(purchaseId),
  }));

  return httpService
    .post<PaymentCreationSuccessResponse, CreateSubscriptionPaymentDto>(
      '/locations/:locationId/subscription-shops/:subscriptionShopId/payments/',
      {
        method,
        issuer: issuer ?? '',
        userDetails,
        purchases,
        usedCodes,
        bookingFee: price,
        discountedBookingFee: discountedPrice
      },
    )
    .then(({ data }): string => data.url);
}

/**
 * Checks the status of a payment
 *
 * @param paymentToken The payment token
 * @returns The payment status
 */
export async function checkPaymentStatus(paymentToken: string): Promise<string> {
  return httpService
    .post<string, PaymentTokenBody>(`/payments/status/:locationId`, {
      paymentToken,
    })
    .then(({ data }): string => data);
}

export async function getGooglePaymentData(paymentToken: string): Promise<GooglePaymentData> {
  return httpService
    .post<GooglePaymentData>(`/api/payments/google`, {
      paymentToken,
    })
    .then(({ data }): GooglePaymentData => data);
}

/**
 * Returns the subscriptions tied to a payment token as a PDF
 * @param paymentToken the payment token
 * @returns The PDF buffer
 */
export async function getSubscriptionsAsPdf(paymentToken: string): Promise<Buffer> {
  return httpService
    .post<Buffer, PaymentTokenBody>(
      '/cardholders',
      { paymentToken },
      { headers: { 'Content-Type': 'application/json' }, responseType: 'arraybuffer' },
    )
    .then(({ data }): Buffer => data);
}
