import React, { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconType } from 'react-icons';
import Icon from '../icons/Icon';

type IconButtonProps = {
  icon: IconType;
  iconClassName?: string;
  label?: string;
  labelClassName?: string;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

export default function IconButton({
  icon,
  className,
  iconClassName,
  label,
  labelClassName,
  ...props
}: IconButtonProps): JSX.Element {
  return (
    <button
      type="button"
      className={twMerge(
        'h-[30px] w-[30px] rounded-full flex-shrink-0 transition-all flex items-center justify-center disabled:opacity-30 hover:bg-opacity-80 disabled:hover:bg-opacity-100',
        className,
      )}
      {...props}>
      {label && (
        <span className={twMerge("hidden sm:inline", labelClassName)}>
          {label}
        </span>
      )} <Icon icon={icon} className={twMerge('h-4 w-4', iconClassName)} />
    </button>
  );
}
