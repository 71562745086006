import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CgSpinnerAlt } from 'react-icons/cg';
import useQueryParams from '../../hooks/useQuery';
import { DefaultURLQuery, ProgressStatus } from '../../types/misc';
import Button from '../../components/buttons/Button';
import ContentPanel from '../../components/layouts/ContentPanel';
import ProgressComponent from '../../components/misc/ProgressComponent';
import { NOT_FOUND_ROUTE } from '../../constants';
import SuccessFailureCircle from '../../components/misc/SuccessFailureCircle';
import { cartService, paymentService } from '../../services';
import useCrashHandler from '../../hooks/useCrashHandler';
import useDateFormat from '../../hooks/useDateFormat';
import useShop from '../../hooks/selectors/useShop';

export default function OrderSuccessPage(): JSX.Element {
  const crashHandler = useCrashHandler();
  const { payment } = useQueryParams<DefaultURLQuery>();
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'page.orderSuccess' });
  const format = useDateFormat();
  const { locationName } = useShop();
  const [disabledDownloadButton, setDisabledDownloadButton] = useState<boolean>(false);

  useEffect((): void => {
    if (!payment) {
      navigate(NOT_FOUND_ROUTE);
      return;
    }

    cartService.clearCartState();
    sendGooglePaymentData(payment);
  }, [payment]);

  const sendGooglePaymentData = async(paymentToken: string): Promise<void> => {
    paymentService
      .getGooglePaymentData(paymentToken)
      .then((result): void => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'purchase',
          ecommerce: result
        })
      });
  }

  const downloadSubscriptions = async (): Promise<void> => {
    if (!payment) return;
    setDisabledDownloadButton(true);

    paymentService
      .getSubscriptionsAsPdf(payment)
      .then((buffer): void => {
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `subscription-${format(new Date(), 'dd-MM-yyyy')}-${locationName}.pdf`);
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
        setDisabledDownloadButton(false);
      })
      .catch((error) : void => {
        setDisabledDownloadButton(false);
        crashHandler(error);
      });
  };

  return (
    <>
      <div className="flex w-full lg:w-1/3 lg:pt-5 mx-auto ">
        <ProgressComponent status={ProgressStatus.SUCCESS} />
      </div>
      <ContentPanel className="items-center gap-8 pt-16 lg:pb-16">
        <SuccessFailureCircle status={ProgressStatus.SUCCESS} />
        <p className="text-[17px] leading-[22px] lg:max-w-[350px] text-center">
          {t('description')}
        </p>
        <Button variant="pink" onClick={downloadSubscriptions} disabled={disabledDownloadButton}>
        { disabledDownloadButton && <CgSpinnerAlt className="animate-spin mr-3" size={18} /> } {t('button')}
        </Button>
      </ContentPanel>
    </>
  );
}
