import React from 'react';
import { Routes, Route } from 'react-router-dom';
import UserDetailsPage from '../pages/shop/UserDetailsPage';
import PaymentPage from '../pages/shop/PaymentPage';
import DefaultLayout from '../components/layouts/DefaultLayout';
import NotFoundPage from '../pages/NotFoundPage';
import OrderSuccessPage from '../pages/postpayment/OrderSuccessPage';
import ExtendedNavigate from './ExtendedNavigate';
import ProcessPostPaymentPage from '../pages/postpayment/ProcessPostPaymentPage';
import ShopLayout from '../components/layouts/ShopLayout';
import {
  CARDHOLDERS_ROUTE,
  ORDER_FAILURE_ROUTE,
  ORDER_PENDING_ROUTE,
  ORDER_PROCEEDING_ROUTE,
  ORDER_SUCCESS_ROUTE,
  PAYMENT_ROUTE,
  SUBSCRIPTIONS_ROUTE,
  USER_DETAILS_ROUTE,
  NOT_ACTIVE_ROUTE,
} from '../constants';
import OrderFailurePage from '../pages/postpayment/OrderFailurePage';
import SubscriptionsPage from '../pages/shop/SubscriptionsPage';
import SubscriptionCardholdersPage from '../pages/shop/SubscriptionCardholdersPage';
import OrderPendingPage from '../pages/postpayment/OrderPendingPage';
import NotActivePage from '../pages/NotActivePage';

export default function Router(): JSX.Element {
  return (
    <Routes>
      <Route path="" element={<DefaultLayout />}>
        <Route path="" element={<ShopLayout />}>
          <Route index element={<ExtendedNavigate to={SUBSCRIPTIONS_ROUTE} />} />
          <Route path={SUBSCRIPTIONS_ROUTE} element={<SubscriptionsPage />} />
          <Route path={USER_DETAILS_ROUTE} element={<UserDetailsPage />} />
          <Route path={CARDHOLDERS_ROUTE} element={<SubscriptionCardholdersPage />} />
          <Route path={PAYMENT_ROUTE} element={<PaymentPage />} />
        </Route>
        <Route path={ORDER_PROCEEDING_ROUTE} element={<ProcessPostPaymentPage />} />
        <Route path={ORDER_SUCCESS_ROUTE} element={<OrderSuccessPage />} />
        <Route path={ORDER_PENDING_ROUTE} element={<OrderPendingPage />} />
        <Route path={ORDER_FAILURE_ROUTE} element={<OrderFailurePage />} />
        <Route path={NOT_ACTIVE_ROUTE} element={<NotActivePage />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
}
