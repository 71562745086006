import React, { useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { FaShoppingBasket } from 'react-icons/fa';
import classNames from 'classnames';
import { useNavigate } from 'react-router-dom';
import { CSSObjectWithLabel } from 'react-select';
import { useTranslation } from 'react-i18next';
import ContentPanel from '../../components/layouts/ContentPanel';
import { cartService, subscriptionShopService } from '../../services';
import { SubscriptionPurchaseDto, SubscriptionType } from '../../types/subscription';
import useShop from '../../hooks/selectors/useShop';
import DropdownInput from '../../components/inputs/DropdownInput';
import IconButton from '../../components/buttons/IconButton';
import useCrashHandler from '../../hooks/useCrashHandler';
import useDateFormat from '../../hooks/useDateFormat';
import useQueryParams from '../../hooks/useQuery';
import { DefaultURLQuery } from '../../types/misc';
import { NOT_ACTIVE_ROUTE } from '../../constants';

const MAX_SUBSCRIPTIONS_IN_DROPDOWN = 50;

export default function SubscriptionsPage(): JSX.Element {
  const { subscriptionTypes } = useShop();
  const { lId, sId } = useQueryParams<DefaultURLQuery>();
  const navigate = useNavigate();
  const crashHandler = useCrashHandler();

  useEffect((): void => {
    subscriptionShopService
      .checkIfShopIsAvailable(lId ?? -1, sId ?? -1)
      .then((isAvailable): void => {
        if (!isAvailable) {
          navigate(NOT_ACTIVE_ROUTE);
        }
      })
      .catch(crashHandler);

    subscriptionShopService.getSubscriptionShop().catch(crashHandler);
    subscriptionShopService.getSubscriptionTypes().catch(crashHandler);
  }, []);

  return (
    <ContentPanel className="gap-2 p-2">
      {[...subscriptionTypes].sort((s1, s2): number => s1.orderPriority - s2.orderPriority).map((t): JSX.Element => (
        <SubscriptionComponent key={t.id} subscription={t} />
      ))}
    </ContentPanel>
  );
}

interface SubscriptionComponentProps {
  subscription: SubscriptionType;
}
function SubscriptionComponent({ subscription }: SubscriptionComponentProps): JSX.Element {
  const crashHandler = useCrashHandler();
  const [count, setCount] = useState(0);
  const format = useDateFormat();
  const { t } = useTranslation();

  const handleSubmit = (): void => {
    if (!count) return;

    const items: SubscriptionPurchaseDto[] = Array.from({ length: count }).map((): SubscriptionPurchaseDto => ({
      purchaseId: v4(),
      subscriptionTypeId: subscription.id,
      price: subscription.price,
      cardholders: [],
    }));

    cartService.addItemsToCart(items).catch(crashHandler);
  };

  return (
    <div className="bg-sb-light-blue rounded-sb-md w-full flex flex-col px-4 py-4 gap-4">
      <div className="flex flex-col">
        <p className="font-ginto-bold font-[17px] leading-[22px]">
          {subscription.name.toLowerCase()}
        </p>
        <p className="font-[15px] leading-[22px]">
          {format(subscription.capacityGroup.startDate, 'dd-MM-yyyy')} -{' '}
          {format(subscription.capacityGroup.endDate, 'dd-MM-yyyy')}
        </p>
        {subscription.description && (
          <p className="text-[15px] leading-[21px] opacity-30">
            {subscription.description.toLowerCase()}
          </p>
        )}
      </div>
      <div className="flex items-center w-full gap-2">
        <div className="flex gap-2 w-full justify-end">
          <p className="h-[38px] text-[15px] flex items-center">€{subscription.price.toFixed(2)}</p>
          <DropdownInput
            options={Array.from({ length: MAX_SUBSCRIPTIONS_IN_DROPDOWN }, (_, i): number => i + 1).map(
              (n): { label: string, value: number } => ({
                value: n,
                label: n.toString(),
              }),
            )}
            setValue={(v): false | void => !!v && setCount(v)}
            value={count}
            key={count}
            placeholder="0"
            styles={{
              container: (baseStyles): CSSObjectWithLabel => ({
                ...baseStyles,
                width: '100%',
                maxWidth: '100px',
                position: 'relative'
              }),
              placeholder: (baseStyles): CSSObjectWithLabel => ({ ...baseStyles, color: 'black' }),
              control: (): CSSObjectWithLabel => ({
                height: '30px',
                width: '100%',
                borderWidth: '0px',
                minWidth: '75px',
                maxWidth: '100px',
              }),
              menu: (baseStyles): CSSObjectWithLabel => ({
                ...baseStyles,
                zIndex: 20,
                position: 'absolute',
                top: '100%',
                left: 0,
                right: 0,
                marginTop: '4px',
              }),
              menuList: (baseStyles): CSSObjectWithLabel => ({
                ...baseStyles,
                zIndex: 20,
                maxHeight: '200px',
              }),
            }}
          />
          <IconButton
            icon={FaShoppingBasket}
            onClick={handleSubmit}
            iconClassName="w-4 h-4 sm:ml-2"
            disabled={!count}
            label={t('page.subscriptions.add_to_cart')}
            className={classNames('disabled:opacity-50 h-[34px] mt-auto mb-[2px] w-[34px] bg-white sm:w-[150px]', {
              'bg-sb-purple': count,
            })}
          />
        </div>
      </div>
    </div>
  );
}
