export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3500';

export const SB_PINK = '#EF02FA';
export const SB_BLUE = '#005AFF';
export const SB_PURPLE = '#7F68E4';
export const SB_GREEN = '#9CF3CA';
export const SB_LIGHT_PINK = '#EF02FA4D';
export const SB_LIGHT_BLUE = '#8FD3EA66';
export const SB_LIGHT_PURPLE = '#7F68E44D';
export const SB_LIGHT_GREEN = '#9CF3CA4D';
export const SB_DARK_PURPLE = '#191A36';

export const WEB_PAGE_URL = 'https://schaatsbaanrotterdam.nl/';
export const SUBSCRIPTIONS_ROUTE = '/subscriptions';
export const USER_DETAILS_ROUTE = '/user-details';
export const CARDHOLDERS_ROUTE = '/cardholders';
export const PAYMENT_ROUTE = '/payment';
export const ORDER_SUCCESS_ROUTE = '/order/success';
export const ORDER_PENDING_ROUTE = '/order/pending';
export const ORDER_FAILURE_ROUTE = '/order/failure';
export const ORDER_PROCEEDING_ROUTE = '/order/proceeding';
export const NOT_FOUND_ROUTE = '/not-found';
export const NOT_ACTIVE_ROUTE = '/not-active';

export const SHOP_ROUTES = [
  SUBSCRIPTIONS_ROUTE,
  USER_DETAILS_ROUTE,
  CARDHOLDERS_ROUTE,
  PAYMENT_ROUTE,
];

export const AUTO_COMPLETES = {
  firstName: 'given-name',
  lastName: 'family-name',
  email: 'email',
  confirmEmail: 'email',
  zipCode: 'postal-code',
  city: 'address-level2',
};
