import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ContentPanel from '../components/layouts/ContentPanel';
import Button from '../components/buttons/Button';

export default function NotActivePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation('translation', { keyPrefix: 'page.notActive' });

  return (
    <ContentPanel className='lg:mt-[112px]'>
      <h1 className="font-['ginto-bold'] text-[24px]">{t('title')}</h1>
      <p>{t('description')}</p>
      <Button onClick={(): void => navigate('/date?lId=1&sId=1')} className='text-center'>{t("button_try_again")}</Button>
    </ContentPanel>
  );
}
